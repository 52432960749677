import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import Breaker from '../components/Breaker';
import { Title } from '../styles/base';
import SEO from '../components/seo';
import AboutUsPhoto from '../images/AboutUs.jpg';

const Homepage = () => (
  <Layout>
    <SEO title="Sobre Nós" keywords={[`gatsby`, `application`, `react`]} />

    <Title>Sobre Nós</Title>
    <Breaker />

    <Grid>
      <Row center='md' middle='md'>
        <Col md={10}>
          <Row center='xs'>
            <Col md={10} xs={12}>
              <img
                src={AboutUsPhoto}
                alt='Sobre a HDPhotographers'
                style={{ borderRadius: 6 }}
              />
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div style={{
                textAlign: 'left',
                fontSize: 15,
                color: '#888',
                padding: '70px 40px',
                lineHeight: 1.8
              }}>
                <h2 style={{ color: '#333', textAlign: 'center' }}>HDPhotographers</h2>
                <br />

                Tudo acontece quando colocas amor no que fazes.
                Hoje sabemos isso melhor do que há alguns anos atrás.

                <br /><br />

                Quando iniciámos esta caminhada, de contar histórias captando momentos,
                estávamos longe de pensar que o caminho era tão longo, por vezes tão solitário,
                mas tão gratificante ao mesmo tempo.

                <br /><br />

                HDphotographers foi um projeto que nasceu em 2013 e que tem crescido deste
                então. Chegámos até aqui também errando, caindo, mas aprendendo a superar
                e foram as aprendizagens que fizeram de nós quem somos. A frase feita, “pedras no caminho,
                guardo todas, um dia vou construir um castelo”, é sem dúvida uma certeza absoluta no nosso caminho.

                <br /><br />

                O HD é a soma de todos os momentos que captámos, de todas as pessoas com quem nos cruzamos,
                de cada história que acompanhamos... de cada bebé que vimos nascer e crescer, de cada história de amor
                que vimos dar frutos, de cada amizade que vimos solidificar, de cada cliente que passou de cliente
                a amigo, de cada abraço... sorriso... lágrima que guardámos para sempre numa imagem e que terá um valor incalculável.

                <br /><br />

                Para nós, não são simples fotos... são momentos vividos com intensidade, com amor e
                energia que a imagem nos consegue transmite. Cada foto tem uma história, cada
                foto trás um sentimento… um momento que ficou para sempre imortalizado na imagem.
                E isso somos nós, a guardar emoções em imagens. Criar memórias, que aquecem quem delas
                usufrui, que despertam saudade de emoções vividas, em dias que queremos que sejam únicos.

                <br /><br />

                Vale a pena, vale a pena cada momento “nosso”, vale a pena cada noite em
                branco a editar fotos, os dias, as semanas de formação, que nos ajudam
                crescer dia após dia, a ansiedade de ver o trabalho final, o desejo de ver
                a cara de quem recebe a sua história, por nós contada. Vale a pena, pois
                sabemos que vamos fazer outras famílias sorrir e se não é esse o motivo da fotografia, então qual é?

                <br />

                Não vivemos para sempre... mas as imagens viverão. E elas contarão as histórias, elas deixarão por cá cada pedaço de emoção captado e isso... isso é único e maravilhoso.

                <br /><br />

                Tudo acontece quando colocas amor...

                <div style={{
                  fontFamily: 'Covered By Your Grace',
                  fontSize: 32,
                  color: '#222',
                  marginTop: 25
                }}>
                  Hugo & Dulce
                </div>

              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Grid>
  </Layout>
);

export default Homepage;
